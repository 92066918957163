H2 {
  padding: 10px;
}

.canvas-brush {
  position: absolute;
  border: 1px solid #000;
  border-radius: 50%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.canvas-cursor {
  cursor: none;
}

.infinitygallery {
  display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	/* grid-auto-rows: min-content;
	grid-auto-flow: dense; */
}

@media screen and (max-width: 480px) {
  .infinitygallery {
    display: unset;
  }
  .infinitygallery div{
    padding-bottom: 5px;
  }
}

.infinitygallery {
  padding: 10px;
}
.infinitygallery > div {
	/* min-height: 100%; */
  display: flex;
	justify-content: center;
	align-items: center;
}

.infinitypreview {
	width: 100%;
	height: 100%;
	/* object-fit: cover; */
}

.infinitygallery .wide {
	grid-column: span 2;
}
.infinitygallery .pano {
	grid-column: span 4;
}
.infinitygallery .tall {
	grid-row: span 2;
}
.infinitygallery .bookmark {
	grid-row: span 4;
}
.infinitygallery .big {
	grid-column: span 2;
	grid-row: span 2;
}
.infinitygallery .square {
	grid-column: span 1;
	grid-row: span 1;
}
.App {
  text-align: left;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
label {
  margin-bottom: 0px!important;
  padding:3px 0;
}
.chakra-checkbox {
  margin-bottom: 0px!important;
}
.flex_wrap {
  flex-wrap: wrap;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.workspace_list > ul > div {
  background-color: #171923;
  border-radius: 7px;
  border:#171923 2px solid;
}
.workspace_list > ul > div.active {
  border:#7928CA 1px solid;
}
.workspace_list > ul > div:hover,
.workspace_list > ul > div:visited {
  border:#4A5568 1px solid;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.Generate-header {
  border-bottom: #4A5568 1px solid;
}
.chakra-form-control {
  margin: 2em 0.25em;
}
.chakra-avatar > div {
  width: auto;
  height: auto;
  padding: 1px 5px 2px;
  border-radius: 2em;
}
.chakra-avatar > div > small {
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
}
.App-link {
  color: #61dafb;
}
button.chakra-button {
  margin: .5em;
}
.heroicons-sm {
  min-width:8px;
  max-width:14px;
  height:auto;
  margin-right: 8px;
}
.heroicons-md {
  min-width:18px;
  max-width:28px;
  height:auto;
  margin-right: 8px;
}
.opacity-50 {
  opacity: 0.50;
}
.w-100 {
  min-width: 100%;
}
.copy-prompt-container code{
  padding-bottom: 20px;
}
.copy-prompt-btn {
  float: right;
  margin-bottom: 0;
  margin-top: 10px;
}
.chakra-modal__body img.chakra-image {
  margin: auto;
  top:0;
  bottom:0;
  left:0;
  right: 0;
  position:relative;
}
@media (min-width: 976px) {
  .chakra-stack.piece-details {
      margin-left: 2em;
      max-width: 480px;
  }
  .piece-container {
      max-width: 80vw;
      margin: auto;
  }
}

.scrollbox-content {
    word-break: break-all;
    word-wrap: break-word;
}