.flow-editor {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--chakra-colors-gray-900);
  display: flex;
  flex-direction: column;
}

.chakra-ui-dark .flow-editor {
  background-color: var(--chakra-colors-gray-900);
}

.flow-editor .react-flow {
  flex: 1;
  width: 100%;
  height: 100%;
}

.flow-editor .react-flow__container {
  width: 100%;
  height: 100%;
}

.react-flow__node {
  min-width: 160px;
  background: var(--chakra-colors-gray-700);
  color: var(--chakra-colors-white);
  border: 1px solid var(--chakra-colors-gray-600);
  border-radius: 4px;
  transition: all 0.2s ease;
}

.flow-node.selected {
  box-shadow: 0 0 0 2px #7B61FF;
  transition: all 0.2s ease;
}

.react-flow__handle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid var(--chakra-colors-gray-200);
  transition: all 0.2s ease;
  z-index: 1;
}

.react-flow__handle:hover {
  filter: brightness(2);
}

.chakra-ui-dark .react-flow__handle {
  border-color: var(--chakra-colors-gray-500);
}

.react-flow__handle-left {
  left: -6px;
}

.react-flow__handle-right {
  right: -6px;
}

.react-flow__handle.connected {
  background-color: var(--chakra-colors-blue-400) !important;
  border-color: var(--chakra-colors-blue-500) !important;
  transform: scale(1.2);
}

.chakra-ui-dark .react-flow__handle.connected {
  background-color: var(--chakra-colors-blue-600) !important;
  border-color: var(--chakra-colors-blue-500) !important;
}

.react-flow__edge-path {
  stroke: var(--chakra-colors-gray-500);
  stroke-width: 2;
}

.react-flow__edge.selected .react-flow__edge-path,
.react-flow__edge:hover .react-flow__edge-path {
  stroke: var(--chakra-colors-blue-500);
  stroke-width: 3;
}

.react-flow__edge.animated .react-flow__edge-path {
  stroke-dasharray: 5;
  animation: dashdraw 0.5s linear infinite;
}

.react-flow__controls {
  box-shadow: none;
  background: transparent;
}

.react-flow__controls button {
  background: var(--chakra-colors-gray-700);
  border-bottom: 1px solid var(--chakra-colors-gray-600);
  color: var(--chakra-colors-gray-200);
}

.react-flow__controls button:hover {
  background: var(--chakra-colors-gray-600);
}

.chakra-ui-dark .react-flow__controls-button {
  background: var(--chakra-colors-gray-800);
  border-bottom: 1px solid var(--chakra-colors-gray-600);
  color: var(--chakra-colors-gray-100) !important;
}

.react-flow__minimap {
  background-color: var(--chakra-colors-dark);
  border-radius: 8px;
  box-shadow: var(--chakra-shadows-lg);
}

.chakra-ui-dark .react-flow__minimap {
  background-color: var(--chakra-colors-gray-800);
}

.chakra-tabs__tab-panel {
  padding: 0 !important;
}

.react-flow__attribution {
  display: none;
}

.edge-delete-button {
  opacity: 0;
  transition: opacity 0.2s ease;
  background: var(--chakra-colors-gray-700) !important;
}

.react-flow__edge:hover .edge-delete-button {
  opacity: 1;
}

.edge-delete-button:hover {
  background: var(--chakra-colors-red-500) !important;
  color: white !important;
}

@keyframes dashdraw {
  from {
    stroke-dashoffset: 10;
  }
}
